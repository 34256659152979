.app {
  height: 100vh;
  display: flex;
  color: white;
  background-color: #020230;
}

.main {
  width: 75%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #020230),
    url("../assets/bg.jpg") center;
  display: flex;
  flex-direction: column;
}

.pyramid {
  width: 25%;
  background-color: #020230;
  display: flex;
  align-items: center;
  justify-content: center;
}

.moneyList {
  padding: 20px;
  list-style: none;
  width: 100%;
}

.moneyListItem {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.moneyListItem.active {
  background-color: teal;
}

.moneyListItemNumber {
  width: 30%;
  font-size: 25px;
  font-weight: 100;
}

.moneyListItemAmount {
  font-size: 30px;
  font-weight: 300;
}

.top {
  height: 50%;
  position: relative;
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
 
}
.help {
  position: absolute;
  display: flex;
  justify-content: space-between;
}
.help img {
  width: 150px;
  margin: 30px;
  cursor: pointer;
  transition: all 0.5s ease;
  
}
.help img:hover {
  transition: all 0.3s ease;
  transform: scale(1.2);
}
.anime{
  animation: leaves 2s ease-in-out infinite alternate;
}
@keyframes leaves {
  0% {
      transform: scale(0.8);
  }
  100% {
      transform: scale(1.3);
  }
}
.timer {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 80px;
  font-size: 35px;
  font-weight: 700;
}
.AudianceVote{
  background-color: #020230;
  width: 200px;
  height: 300px;
  position: absolute;
  bottom: 10px;
  right: 80px;
  display: flex;
  justify-content: space-between;
  align-items:flex-end
}
.AudianceVote .vote{
  margin: 10px;
  background-color: teal;
  width: 25%;
  height: 60%;
  text-align: center;
}
.bottom {
  height: 50%;
}

.trivia {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.question {
  width: 80%;
  background: linear-gradient(#100241, black);
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid white;
  font-size: 20px;
}

.answers {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.answer {
  width: 40%;
  padding: 10px;
  margin: 0 10px 20px 10px;
  text-align: center;
  background: linear-gradient(#0e0124, #22074d);
  border: 1px solid white;
  border-radius: 15px;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.answer .tag {
  padding-right: 10px;
  font-weight: 300;
  font-size: 20px;
}
.answer .text {
  font-weight: 300;
  font-size: 20px;
}
.answer:hover,
.answer.active {
  background: mediumblue;
}

.answer.correct {
  animation: correct 3s ease forwards;
}

@keyframes correct {
  0%,
  22%,
  42% {
    background: mediumblue;
  }
  20%,
  40%,
  60% {
    background: linear-gradient(#0e0124, #22074d);
  }
  62%,
  100% {
    background: green;
  }
}

.answer.wrong {
  animation: wrong 3s ease forwards;
}

@keyframes wrong {
  0%,
  22%,
  42% {
    background: mediumblue;
  }
  20%,
  40%,
  60% {
    background: linear-gradient(#0e0124, #22074d);
  }
  62%,
  100% {
    background: crimson;
  }
}
.correctWrong{
  background: green;
}

.endText {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.start {
  width: 250px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.startInput {
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
}

.startInput:focus {
  outline: none;
}

.startButton {
  width: 100%;
  height: 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
}
.replay {
  background-color: mediumblue;
  width: auto;
  height: auto;
  border-radius: 5px;
  border: 5px solid white;
  cursor: pointer;
  text-align: center;
}

@media (max-width: 550px) {
  body{
    width: 100%;
    height: 100%;
  }
  .app {
    widows: 100%;
  }
  .main {
    width: 100%;
    margin-right: 30px;
  }

  .pyramid {
    position: absolute;
    width: 10%;
    height: 100%;
    right: 0;
    transition: all 0.3s ease;
  }
  .moneyListItemAmount {
    display: none;
  }
  .moneyListItemNumber {
    font-size: 1.5rem;
  }
  .top {
    height: 30%;
    position: relative;
  }
  .answer {
    width: 90%;
  }
  .question {
    margin: 20px;
  }
  .activateMenu .moneyListItemAmount {
    display: block;
    font-size: 1.3rem;
    right: 0;
  }

  .activateMenu .moneyListItemNumber {
    font-size: 1.3rem;
  }
  .activateMenu {
    width: 60%;
    transition: all 0.3s ease;
  }
  .help img {
    width: 80px;
    margin: 10px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .timer{
    left: 10px;
    bottom: 0;
    height:50px ;
    width: 50px;
  }
  .AudianceVote{
    position: absolute;
    height: 150px;
    top:0;
    left: 0;
    
   
  }
  .AudianceVote .vote{
    margin: 10px;
    background-color: teal;
    width: 25%;
    height: 60%;
    text-align: center;
  }
  .replay {
   font-size: 20px;
  }
  
  .endText {
    font-size: 20px;
  }
}
